

// 引入工具库
import base from './base/base.js'
import oss from './oss/index.js'
import platform from './platform/index.js'


window.xgjzt = {
    base,
    oss,
    platform
}

export default window.xgjzt